@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}

* {
  /* box-sizing: border-box; */
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar { 
  height: 0px;
  width: 0px;
  background: transparent
}

*::-webkit-scrollbar-track:horizontal {
  background: transparent;
}

*::-webkit-scrollbar-thumb:horizontal {
  background: transparent;
  border: none;
}
