.fade-in {
  backdrop-filter: blur(3px);
  flex-direction: column;
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  animation: fadein 1s;
  animation-fill-mode: forwards;
}

.fade-out {
  backdrop-filter: blur(3px);
  flex-direction: column;
  -webkit-animation: fadeout 1s forwards;
  -moz-animation: fadeout 1s forwards;
  animation: fadeout 1s forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
   
}

@keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeout {
    0%   { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes colorChange {
  0% {
    color: hsl(1, 100%, 50%);
    textDecorationColor: hsl(1, 100%, 50%);
  }
  100% {
    color: hsl(359, 100%, 50%);
    textDecorationColor: hsl(359,100%,50%);
  }
}