@keyframes circle {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.circle-loading {
  animation: circle 15s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}